class Props 
{
  constructor() 
  {
    this.baseURL = "https://api.truelifegame.com"; 
    this.keyURL = "/api/key";
    this.loginURL = "/api/login";
    this.registerURL = "/api/register";
    this.getUserURL = "/api/user";
    this.getLogoutURL = "/api/logout";
    //this.email = email;
    //this.secretKey = this.getKey();
  }
  getKey(email)
  {
    let data = 
    {
      email: email,
    }
    let init = 
    {
      method: 'POST', 
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(data)
    }
    fetch(this.keyURL,init).then(res=>res.json()).then(
      data => 
      {
        return data.data.key;        
      }
    ).catch(err=>{console.log(err);});
  }
  getNow()
  {
    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date +' '+ time;
    return dateTime;
  }
  isNumbericKey(e) 
  {
    var charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46)
    {
      return false;
    }
    else
    {
      if(charCode == 46)
      {
        console.log(e);
        return !this.checkStringForChar(e.target.value, '.');        
      }
      else
      {
        return true;
      }
    }
  }
  checkStringForChar(str,chr)
  {
    let chars = str.split('');
    for(let c=0; c < chars.length; c++)
    {
      if(chars[c] == chr)
      {
        console.log( chars[0] + " :.:.:.: " + chr);
        return true;
      }
    }
    return false;
  }
}
module.exports = Props;