import {defineStore} from 'pinia';
import axios from 'axios';
import Props from '../Classes/Props';

export const useAuthStore = defineStore("auth",{
    state: ()=>({
        authUser: null,
        ttl: null,
        userInfo: null
    }),
    getters: {
        user: (state) => state.authUser,
        userData: (state) => state.userInfo
    },
    actions: {
        async getToken()
        {
            let props = new Props();
            let bios = await axios.get(props.baseURL + "/sanctum/csrf-cookie");
        },
        async getUser()
        {
            this.getToken();
            let _self = this;
            const config = {
                headers: { Authorization: `Bearer ${this.authUser}` }
            };
            let props = new Props();
            await axios.get(props.getUserURL,config).then(
            response=>
            {
                console.log(response);
                _self.userInfo = response.data.data.user;
            }).catch(error =>
            {
                let response = error.response;
                if(response.status == 401)
                {
                    console.log("ERRO :::: > " + response.data.message);
                    
                }
                else
                {
                    console.log("ERRO :::: > " + response.data.message);
                }
            });
        },
        async loginUser(data)
        {
            await this.getToken();
            let props = new Props();
            let _self = this;
            await axios.post(props.loginURL, 
            {
                email: data.email,
                password: data.password
            }).then(function (res)
            {
                _self.authUser = res.data.data.token;
                _self.ttl = props.getNow();
                _self.router.push("/home");
            }).catch(function (error)
            {
                let response = error.response;
                
                if(response.status == 401)
                {
                    console.log("ERRO :::: > " + response.data.message);
                }
                else
                {
                    console.log("ERRO :::: > " + response.data.message);
                }
            });
        },
        async registerUser(data)
        {
            await this.getToken();
            let props = new Props();
            let _self = this;
            await axios.post(props.registerURL, 
            {
                name: data.name,
                email: data.email,
                password: data.password,
                password_confirmation: data.password_confirmation
            }).then(function (res)
            {
                _self.router.push("/");
            }).catch(function (error)
            {
                let response = error.response;
                
                if(response.status == 401)
                {
                    console.log("ERRO :::: > " + response.data.message);
                }
                else
                {
                    console.log("ERRO :::: > " + response.data.message);
                }
            });
        },
        async logout()
        {
            const config = {
                headers: { Authorization: `Bearer ${this.authUser}` }
            };
            let props = new Props();
            await axios.post(props.logout,config).then(res=>
            {
                this.authUser = null;
                this.userInfo = null;
                this.ttl = null;
                window.location.reload();
            }).catch(err=>{
                this.authUser = null;
                this.userInfo = null;
                this.ttl = null;
                window.location.reload();
            });
        }
    }
});