<script>
import { useAuthStore } from '../store/Auth';
export default {
  methods: {
    goHome(){
      this.$router.push({name: 'home'})
    },
    logout(){
      const authStore = useAuthStore();
      authStore.logout();
    },
    openCloseMenu()
    {
      console.log("l");
      let menu = document.getElementById("userMenu");
      if(menu.style.opacity > 0)
      {
        menu.style.opacity = "0";
      }
      else
      {
        menu.style.opacity = "100";
      }
    },
    click: function (ev) {
      console.log(ev.offsetX, ev.offsetY)
    }
  }
}
</script>
<template>
    <div class="hdr">
        <div id="logo" @click="goHome"></div>
        <div id="mainHeaderArea">...</div>
        <div v-if="['home', 'about','jobs','orders'].includes($route.name)">
          <div id="userCorner" @click="openCloseMenu"></div>
        </div>
        <table id="userMenu">
          <tr>
            <td><ic class="settings"></ic></td>
            <td>Settings</td>
          </tr>
          <tr>
            <td><ic class="data"></ic></td>
            <td>User Data</td>
          </tr>
          <tr>
            <td><ic class="logout"></ic></td>
            <td style="color:rgba(255,100,100,1);" @click="logout">Logout</td>
          </tr>
          <a></a>
          <a ></a>
        </table>
    </div>  
</template>
<style scoped>
.hdr
{
  width: 100%;
  background-color: #071924;
  position: relative;
  padding: 10px;
  padding-left: 100px;
  padding-right: 100px;
  box-sizing: border-box;
  vertical-align: top;
  display: flex;
  justify-items: auto;
  
}
#logo
{
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 10px;
  background-image: url("../assets/logo.png");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(255,255,255,.2);
  display: flex;
  align-self: flex-start;
  border-radius: 90px;
}
#logo:hover
{
    cursor:pointer;
}
#mainHeaderArea
{
  width: calc( 100% - 140px);
  height: 100%;
  display: inline-block;
  font-size: 40px;
  vertical-align: middle;
  box-sizing: border-box;
  padding: 34px;
}
#userCorner
{
  width: 50px;
  height: 50px;
  background: #2c3e50;
  padding: 10px;
  background-image: url("../assets/person.svg");
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  justify-self: flex-end;
  border-radius: 90px;
}
#userCorner:hover
{
  cursor: pointer;
}
#userMenu
{
  position: absolute;
  opacity: 0;
  width: 150px;
  top:100px;
  right:60px;
  background-color: rgba(255,255,255,.2);
  padding: 10px;
  border-radius: 8px;
  transition: all .3s;
}
#userMenu .settings
{
   background-image: url('../assets/settings.svg');
}
#userMenu .data
{
   background-image: url('../assets/description.svg');
}
#userMenu .logout
{
   background-image: url('../assets/logout.svg');
}
#userMenu tr
{
  width: 100%;
  font-size: 13px;
  display: inline-block;
  padding: 6px;
  color: #ddd;
  box-sizing: border-box;
  text-align: left;
  vertical-align: top;
}
#userMenu td
{
  text-align: left;
  vertical-align: middle;
  padding-left: 10px;
}
#userMenu ic
{
  display: inline-block;
  background-position: center;
  background-size: contain;
  width: 20px;
  height: 20px;
  padding: 0;
  margin:0;
  box-sizing: border-box;
  vertical-align: top;
}
#userMenu p
{
  display: inline-block;
  box-sizing: border-box;
  height: 100%;
  vertical-align: top;
}
#userMenu tr:hover
{
  cursor: pointer;
  background-color: rgba(255,255,255,.2);
}
</style>