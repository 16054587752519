<!DOCTYPE html>
<script>
import headr from './components/header.vue';
import navigator from './components/navigator.vue';
export default{
  components: {
    headr,
    navigator
  }
}
</script>
<template>
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap" rel="stylesheet"> 
  <headr />
  <div v-if="['home', 'about','jobs', 'orders'].includes($route.name)">
    <navigator />
  </div>
  <router-view/>
</template>
<style>
body
{
  background-color: #071924;
  margin:0;
  padding: 0;
}
#app 
{
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: 'Roboto', sans-serif;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
