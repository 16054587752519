

<script setup>
import { onMounted } from 'vue';
import { useAuthStore } from '../store/Auth';
// @ is an alias to /src

const authStore = useAuthStore();

onMounted(async ()=>
{
  if(authStore.user == null)
  {
    this.$router.push('/');
  }
  await authStore.getUser();
});

</script>
<template>
    <div class="home">
      <h1>Home</h1>
    </div>
    <div v-if="authStore.user">
      <h1>{{authStore.userInfo?.name}}</h1>
      <h1>{{authStore.ttl}}</h1>
    </div>
</template>