<script setup>
  import { ref}  from 'vue';
  import { useAuthStore } from '../store/Auth';

  const authStore = useAuthStore();

  const form = ref({
    name: "",
    email: "",
    password: "",
    password_confirmation: ""
  });
</script>
<script>
  export default
  {
    methods: {
      goBackLogin(){
        this.$router.push({name: 'login'})
      }
    }
  }
</script>

<template>
  <div id="registerForm">
    <form @submit.prevent="authStore.registerUser(form)">
      <input placeholder="Name" type="text" required v-model="form.name"/><br>
      <input placeholder="Email" type="email" required v-model="form.email"/><br>
      <input placeholder="Password" type="password" required v-model="form.password"/><br>
      <input placeholder="Password Confirmation" type="password" required v-model="form.password_confirmation"/><br>
      <button>Submit</button>
    </form>
    <a href="#" @click="goBackLogin">Back to Login</a>
  </div>
</template>
<style scoped>
  #registerForm
  {
    position: relative;
    width: 230px;
    padding: 20px;
    background-color: rgba(250,250,250,1);
    display: inline-block;
    border-radius: 8px;
    margin-top:50px;
    border:solid 1px rgba(220,220,220,1)
  }
  #registerForm input,button
  {
    margin-bottom: 12px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    border: solid 1px rgba(220,220,220,1);
    border-radius: 5px;
  }
  #registerForm button
  {
    background-color:rgba(0,150,150,1);
    font-size: 16px;
    color:white;
  }
  #registerForm button:hover
  {
    background-color: rgba(0,170,160,1);
    cursor: pointer;
  }
  #registerForm a
  {
    display: inline-block;
    text-decoration: none;
    color:rgba(40,40,40,1);
    font-size: 14px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: rgba(235,235,235,1);
  }
  #registerForm a:hover
  {
    background-color: rgba(225,225,225,1);
  }
</style>
