<script setup>
  import { ref}  from 'vue';
  import { useAuthStore } from '../store/Auth';

  const authStore = useAuthStore();

  const form = ref({
  email: "",
  password: ""
  });
</script>
<script>
  export default
  {
    methods: {
      goSignUP(){
        this.$router.push({name: 'register'})
      }
    }
  }
</script>

<template>
  <div id="loginForm">
    <form @submit.prevent="authStore.loginUser(form)">
      <input type="email" placeholder="Email" required v-model="form.email"/><br>
      <input type="password" placeholder="Password" required v-model="form.password"/><br>
      <button>Sign In</button>
    </form>
    <a href="#" @click="goSignUP">Sign Up</a>
  </div>
</template>
<style scoped>
  #loginForm
  {
    position: relative;
    width: 230px;
    padding: 20px;
    background-color: rgba(250,250,250,1);
    display: inline-block;
    border-radius: 8px;
    margin-top:50px;
    border:solid 1px rgba(220,220,220,1)
  }
  #loginForm input,button
  {
    margin-bottom: 12px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    border: solid 1px rgba(220,220,220,1);
    border-radius: 5px;
  }
  #loginForm button
  {
    background-color:rgba(0,150,150,1);
    font-size: 16px;
    color:white;
  }
  #loginForm button:hover
  {
    background-color: rgba(0,170,160,1);
    cursor: pointer;
  }
  #loginForm a
  {
    display: inline-block;
    text-decoration: none;
    color:rgba(40,40,40,1);
    font-size: 14px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: rgba(235,235,235,1);
  }
  #loginForm a:hover
  {
    background-color: rgba(225,225,225,1);
  }
</style>