import { createApp, markRaw } from 'vue'
import App from './App.vue'
import router from './router'
import {createPinia} from 'pinia' 

import './decor/css.css';
import './decor/styleguides.css';

import './axios.js'

const pinia = createPinia();

pinia.use(({store})=>{
    store.router = markRaw(router);
});

const app = createApp(App);

app.use(pinia);
app.use(router).mount('#app');
