<template>
  <h1>Details</h1>
  <p>Job Id : {{id}}</p>
</template>

<script>
export default {
    data(){
        return{
            id: this.$route.params.id
        }
    }
}
</script>

<style>

</style>