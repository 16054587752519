<script setup>
import { onMounted } from 'vue';
import { useAuthStore } from '../store/Auth';
import Props from '../Classes/Props';
// @ is an alias to /src

const authStore = useAuthStore();

onMounted(async ()=>
{
    console.log(authStore.user);
  if(authStore.user == null)
  {
    this.$router.push('/');
  }
  await authStore.getUser();
});
</script>
<script>
export default {
  methods: {
    selectThisTB(e)
    {
        let collection = e.target.parentNode.children;
        for(let c=0; c < collection.length; c++) 
        {
            collection[c].style.color = "#888";
        };
        this.direction = e.target.innerText;
        e.target.style.color = "#FFF";
    },
    submitOrder(e)
    {
        
    },
    checkNumeric(e)
    {
        let props = new Props();
        if(!props.isNumbericKey(e))
        {
            e.preventDefault();
        }
    },
    validateFields(e)
    {
        
    }
  }
}
    
</script>
<template>
    <div class="home">
      <h1>Open New Position</h1>
    </div>

    <div class="form-position">
        <table>
            <tr>
                <td>
                    <p>Contract</p>
                </td>
                <td>
                    <select name="cars" id="cars">
                        <option value="volvo">Volvo</option>
                        <option value="saab">Saab</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Direction</p>
                </td>
                <td>
                    <tb @click="selectThisTB">Long</tb> / <tb @click="selectThisTB">Short</tb>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Order Size</p>
                </td>
                <td>
                    <input type="text" @keypress="checkNumeric"/>
                </td>
            </tr>
             <tr>
                <td>
                    <p>Stop Loss</p>
                </td>
                <td>
                    <input type="text" @keypress="checkNumeric"/>
                </td>
            </tr>
             <tr>
                <td>
                    <p>Take Profit</p>
                </td>
                <td>
                    <input type="text" @keypress="checkNumeric"/>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <button @click="submitOrder">Submit Order</button>
                </td>
            </tr>
        </table>
    </div>
    <span class="divider"></span>
    <p>Position History</p>
    <table id="dynDataPos">
        <th>
            <td>Data / Hora</td>
            <td>Contract</td>
            <td>Direction</td>
            <td>Order Type</td>
            <td>Order Size</td>
            <td>Entry Price</td>
            <td>Exit Price</td>
            <td>P/L $</td>
            <td>P/L %</td>
            <td>R/R</td>
        </th>
    </table>
</template>
<style>
.form-position
{
    display: inline-block;
    font-size: 13px;
}

.form-position td
{
    text-align: left;
    padding-right: 26px;
    padding: 8px;
}
.form-position td p 
{
    color: #ddd;
    
}
.form-position select
{
    border: solid 1px #80DDDF;
    padding: 6px;
    width: 100%;
    border-radius: 3px;
    background-color:#071924;
    color: #EEE;
}
.form-position tb
{
    padding: 6px;
    color: #888;
}
.form-position tb:hover
{
    cursor: pointer;
}
.form-position input
{
    border: solid 1px #80DDDF;
    padding: 6px;
    border-radius: 3px;
    background-color:#071924;
    color: #EEE;
}
.form-position button
{
    width: 100%;
    padding: 8px;
    background-color: #80DDDF;
    border-radius: 3px;
    border: solid 1px #80DDDF;
    transition: all .3s;
}
.form-position button:hover
{
    cursor: pointer;
    background-color: #66FFEF;
    border: solid 1px #66FFFF;
}
.divider
{
    display: block;
    width: 100%;
    height:0px;
    border:solid 1px #002F4A;
    margin-top: 20px;
    margin-bottom: 20px;
}
#dynDataPos
{
    display: block;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    box-sizing: border-box;
    background-color: rgba(0,0,0,.3);
}
#dynDataPos td
{
    padding: 10px;
    width: 10%;
    padding-left: 45px;
    font-size: 13px;
    color: #ccc;
}
</style>