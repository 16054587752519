<template>
  <h1>Jobs</h1>
  <div v-for="job in jobs" :key="job.id">
    <router-link :to="{ name:'JobDetails', params: { id: job.id} }">
      <h2>{{job.title}}</h2>
    </router-link>
  </div>
</template>

<script>
import props from '../../Classes/Props.js'
export default {
    data(){
        return{
            jobs:[
                {title: "t1", id:1, details: "Struff1"},
                {title: "t2", id:2, details: "Struff2"},
                {title: "t3", id:3, details: "Struff3"},
                {title: "t4", id:4, details: "Struff4"},
                {title: "t5", id:5, details: "Struff5"}
            ]
        }
    },
    mounted()
    {
      /*let data = {
        email: "mduarte83@gmail.com",
        password: "Ab12332112"
      }
      let init = 
      {
        method: 'POST', 
        mode: 'cors',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        //redirect: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      }
      let ps = new props();
      fetch(ps.loginURL,init).then(res=>res.json()).then(
        data => 
        {
          console.log(data);
        }
      ).catch(err=>{console.log(err);});*/
    }
}
</script>

<style>

</style>